<template>
	<div id="box_time" style="padding-left: 40px">
		<el-timeline>
			<el-timeline-item
				v-for="(activity, index) in recordslist"
				:key="index"
				color="#1082FF"
			>
				<span class="time_title">{{ activity.dealType }}</span
				><span class="time_subtitle"> {{ activity.createTime }}</span>
				<div class="box_content" v-if="activity.dealType != '隐患提交'">
					<div v-if="activity.dealType == '维修安排'">
						<div>受理人：{{ activity.userName || "-" }}</div>
						<div>维修员：{{ activity.appointUserName || "-" }}</div>
					</div>
					<div v-if="activity.dealType == '报修记录'">
						<div>记录人：{{ activity.operateName || "-" }}</div>
					</div>
					<div v-if="activity.dealType == '工单回访'">
						<div>回访结果：{{ activity.dealResult || "-" }}</div>
						<div>备注：{{ activity.remark || "-" }}</div>
						<div>操作人：{{ activity.operateName || "-" }}</div>
					</div>
					<div v-if="activity.dealType == '工单处理'">
						<div>维修员：{{ activity.appointUserName || "-" }}</div>
						<div>维修结果：{{ activity.dealResult || "-" }}</div>
						<div v-if="activity.dealResult == '未维修'">
							<div>备注：{{ activity.remark || "-" }}</div>
							<div
								style="display: flex; flex-wrap: wrap"
								v-if="activity.url"
							>
								<el-image
									v-for="(url, index) in activity.url.split(
										','
									)"
									:key="index"
									style="
										width: 100px;
										height: 100px;
										border: 1px solid #dddddd;
										margin: 5px;
									"
									:src="url"
									:preview-src-list="activity.url.split(',')"
									:z-index="index"
								>
								</el-image>
							</div>
						</div>
					</div>
					<div v-if="activity.dealType == '维修转派'">
						<div>受理部门：{{ activity.deptName || "-" }}</div>
						<div>受理人：{{ activity.userName || "-" }}</div>
						<div>操作人：{{ activity.operateName || "-" }}</div>
					</div>
					<div v-if="activity.dealType == '工单关闭'">
						<div>备注：{{ activity.remark || "-" }}</div>
						<div>操作人：{{ activity.operateName || "-" }}</div>
					</div>
				</div>
			</el-timeline-item>
		</el-timeline>
	</div>
</template>

<script>
	export default {
		name: "orderTrace",
		props: {
			recordslist: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {};
		},
	};
</script>

<style scoped lang="less">
	#box_time {
		.time_title {
			color: #1fa5f9;
			font-size: 16px;
		}
		.time_subtitle {
			color: #606266;
			margin-left: 20px;
			font-size: 16px;
		}
		.box_content {
			margin-top: 5px;
			padding: 10px;
			border-radius: 5px;
			color: #303133;
			background-color: #f6f7fc;
		}
		/deep/.el-timeline-item__wrapper {
			top: -6px !important;
		}
	}
</style>