<template>
	<div class="box">
		<!-- <div class="button-box">
			<el-button size="small" @click="backtrack">返回</el-button>
		</div> -->
		<div class="flex_box">
			<div class="message">
				<div class="detil-code" v-if="type == 1">报修信息</div>
				<div class="detil-code" v-if="type == 2">用户信息</div>
				<div class="detil-code" v-if="type == 3">事件信息</div>
				<News :information="information" :type="type"></News>
			</div>
			<div class="order">
				<div class="detil-code">工单追踪</div>
				<order-trace :recordslist="recordslist"></order-trace>
			</div>

			<div class="moban" v-if="itemData && itemData.length > 0">
				<div class="detil-code flex">
					<span style="font-size: 16px">维修内容</span>
					<el-button
						type="primary"
						size="mini"
						class="btn"
						plain
						v-if="information.location"
						@click="showDetil"
						>打卡位置</el-button
					>
				</div>
				<TemplateOrder
					v-if="type != 2"
					:data="itemData"
				></TemplateOrder>
				<IssueRegister
					v-if="type == 2"
					:data="itemData"
				></IssueRegister>
				<div class="detil-code flex" v-if="information.signature || information.url">
					<span style="font-size: 16px">用户确认</span>
				</div>
				<div>
					<div
						style="
							display: flex;
							flex-wrap: wrap;
							padding-left: 35px;
						"
					>
						<div v-if="information.signature">
							<el-image
								v-for="(url, index) in information.signature.split(
									','
								)"
								:key="index"
								style="
									width: 100px;
									height: 100px;
									border: 1px solid #dddddd;
									margin: 5px;
								"
								:src="url"
								:preview-src-list="information.signature.split(',')"
								:z-index="index"
							>
							</el-image>
						</div>
						<div v-if="information.url">
							<el-image
								v-for="(url, index) in information.url.split(
									','
								)"
								:key="index"
								style="
									width: 100px;
									height: 100px;
									border: 1px solid #dddddd;
									margin: 5px;
								"
								:src="url"
								:preview-src-list="information.url.split(',')"
								:z-index="index"
							>
							</el-image>
						</div>
					</div>
				</div>
			</div>
		</div>
		<map-locus ref="mapLocus"></map-locus>
	</div>
</template>

<script>
	import OrderTrace from "./components/orderTrace";
	import News from "./components/News";
	import TemplateOrder from "./components/templateOrder";
	import IssueRegister from "./components/issueRegister";
	import mapLocus from "./components/mapLocus.vue";
	import {
		repairOrderDetails,
		securityOrderDetails,
		checkingOrderDetails,
	} from "@/RequestPort/mainOrder";

	export default {
		name: "seeDetil",
		components: { OrderTrace, News, TemplateOrder, IssueRegister, mapLocus },
		data() {
			return {
				itemData: [],
				information: null,
				type: "1",
				dataId: null,
				recordslist: [],
			};
		},
		mounted() {
			this.type = this.$route.query.type;
			this.dataId = this.$route.query.dataId;
			this.getDataDetails();
		},
		methods: {
			getDataDetails() {
				let { type, dataId } = this;

				if (type == 1) {
					repairOrderDetails({ dataId }).then((res) => {
						this.information = res.data;
						this.recordslist = res.data.records;
						this.itemData = res.data.item;
						console.log(res);
					});
				}
				if (type == 2) {
					securityOrderDetails({ dataId }).then((res) => {
						this.information = res.data;
						this.recordslist = res.data.records;
						this.itemData = [res.data.scuItem];
					});
				}
				if (type == 3) {
					checkingOrderDetails({ dataId }).then((res) => {
						this.information = res.data;
						this.recordslist = res.data.records;
						this.itemData = res.data.item;
					});
				}
			},
			showDetil() {
				let arr = this.information.location
					? this.information.location.split(" ")
					: [];
				this.$refs.mapLocus.open(arr);
			},
			backtrack() {
				this.$router.back(-1);
			},
		},
	};
</script>

<style scoped lang="scss">
	.message {
		width: 400px;
		border-right: 1px solid #ebebeb;
		height: calc(100vh -  108px);
		overflow: auto;
		padding-right: 30px;
	}
	.order {
		width: 459px;

		height: calc(100vh -  108px);
		overflow: auto;
		padding-right: 30px;
	}
	.moban {
		border-left: 1px solid #ebebeb;
		flex: 1;
		height: calc(100vh - 108px);
		overflow: auto;
		padding-right: 30px;
	}
	.box {
		height: calc(100vh - 91px);
		background-color: #fff;
		width: 99%;
		margin: 10px;
		border-radius: 5px;
	}
	.button-box {
		padding: 20px 0 10px 20px;
	}
	.flex_box {
		display: flex;
	}
	.detil-code {
		margin-left: 30px;
		padding-left: 9px;
		padding-top: 20px;
		font-size: 16px;
		height: 16px;
		line-height: 16px;
		color: #000000;
		position: relative;
		margin-bottom: 20px;
		font-weight: bolder;
	}
	.detil-code.flex {
		position: relative;
		.btn {
			position: absolute;
			right: 0;
			top: 14px;
		}
	}
	.detil-code:before {
		content: "";
		position: absolute;
		width: 3px;
		height: 16px;
		background-color: #007aff;
		top: 20px;
		left: 0;
	}
</style>